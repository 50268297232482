import React from "react"
import HandWave from "../../assets/svgs/HandWave.svg"
import Question from "../../assets/svgs/Question.svg"
import Shield from "../../assets/svgs/Shield.svg"
import SideEffects from "../../assets/svgs/SideEffects.svg"
import Square from "../../assets/svgs/Square.svg"
import Stats from "../../assets/svgs/Stats.svg"

const JumpLinks = ({ linkGroups }) => {
  const item = (Icon, link, type) => (
    <a className={`jump-links__${type}`} href={link.link}>
      <Icon className={`icon icon--${link.color}`} />
      <span
        className="label"
        dangerouslySetInnerHTML={{ __html: link.label }}
      />
    </a>
  )
  return (
    <div className="jump-links">
      {linkGroups.map((linkGroup, linkGroupIndex) => (
        <React.Fragment key={linkGroupIndex}>
          {
            {
              buttons: (
                <div className="jump-links__button-group">
                  {linkGroup.links.map((link, linkIndex) => (
                    <React.Fragment key={linkIndex}>
                      {
                        {
                          handWave: item(HandWave, link, "button"),
                          question: item(Question, link, "button"),
                          shield: item(Shield, link, "button"),
                          sideEffects: item(SideEffects, link, "button"),
                          square: item(Square, link, "button"),
                          stats: item(Stats, link, "button"),
                        }[link.icon]
                      }
                    </React.Fragment>
                  ))}
                </div>
              ),
              circles: (
                <ul className="jump-links__circle-group">
                  {linkGroup.links.map((link, linkIndex) => (
                    <li className="jump-links__list" key={linkIndex}>
                      {
                        {
                          handWave: item(HandWave, link, "circle"),
                          question: item(Question, link, "circle"),
                          shield: item(Shield, link, "circle"),
                          sideEffects: item(SideEffects, link, "circle"),
                          square: item(Square, link, "circle"),
                          stats: item(Stats, link, "circle"),
                        }[link.icon]
                      }
                    </li>
                  ))}
                </ul>
              ),
            }[linkGroup.type]
          }
        </React.Fragment>
      ))}
    </div>
  )
}

export default JumpLinks
