import React, { useRef } from "react"
import HandWave from "../../assets/svgs/HandWave.svg"
import Question from "../../assets/svgs/Question.svg"
import Shield from "../../assets/svgs/Shield.svg"
import SideEffects from "../../assets/svgs/SideEffects.svg"
import Stats from "../../assets/svgs/Stats.svg"
import Square from "../../assets/svgs/Square.svg"

const FloatingLinks = ({ shouldShow }) => {
  const floatingRef = useRef()

  const handleClick = () => {
    floatingRef.current.classList.toggle("floating-links--open")
  }

  const handleAnchor = event => {
    const isOpen = floatingRef.current.classList.contains(
      "floating-links--open"
    )
    event.stopPropagation()
    if (!isOpen) {
      event.preventDefault()
    }
    floatingRef.current.classList.toggle("floating-links--open")
  }

  return (
    <ul
      className={`floating-links ${shouldShow ? "floating-links--show" : ""}`}
      ref={floatingRef}
      onClick={handleClick}
    >
      <li className="floating-links__link">
        <a href="/#what-is-endometriosis" onClick={handleAnchor}>
          <span className="text">WHAT IS ENDOMETRIOSIS?</span>
          <Question className="icon icon--what-is" />
        </a>
      </li>{" "}
      <li className="floating-links__link">
        <a href="/#meet-myfembree" onClick={handleAnchor}>
          <span className="text">MEET MYFEMBREE</span>
          <HandWave className="icon icon--meet" />
        </a>
      </li>
      <li className="floating-links__link">
        <a href="/#relieves-pain" onClick={handleAnchor}>
          <span className="text">FOR 3 TYPES OF ENDO PAIN</span>
          <Stats className="icon icon--results" />
        </a>
      </li>
      <li className="floating-links__link">
        <a href="/#side-effects" onClick={handleAnchor}>
          <span className="text">SIDE EFFECTS</span>
          <SideEffects className="icon icon--side-effects" />
        </a>
      </li>
      <li className="floating-links__link">
        <a href="/#cost-support" onClick={handleAnchor}>
          <span className="text">COST & SUPPORT</span>
          <Square className="icon icon--cost-support" />
        </a>
      </li>
    </ul>
  )
}

export default FloatingLinks
