import React, { useEffect, useRef, useState } from "react"
import ExternalLink from "../exitramp/ExternalLink"
import FloatingLinks from "../FloatingLinks/FloatingLinks"
import JumpLinks from "../JumpLinks/JumpLinks"
import Layout from "../Layout"
import { Container } from "react-bootstrap"

import BackgroundPattern from "../../assets/svgs/BackgroundPattern.svg"
import Calendar from "../../assets/svgs/Calendar.svg"
import DownloadCircle from "../../assets/svgs/DownloadCircleWhiteFuchsia.svg"
import ExternalIcon from "../../assets/svgs/ExternalIcon.svg"
import HeroHomeDesktop from "../../assets/images/home-hero-desktop.png"
import HeroHomeMobile from "../../assets/images/home-hero-mobile.png"
import InternalArrow from "../../assets/svgs/InternalArrow.svg"
import Phone from "../../assets/svgs/Phone.svg"
import RelievePain from "../../assets/svgs/RelievePain.svg"
import blueShield from "../../assets/images/BlueSheild.png"
import brochureImg from "../../assets/images/myfembree-brochure-preview.png"
import brochurePDF from "../../assets/images/Myfembree-Digital-Patient-Brochure.pdf"
import circleExclamationImg from "../../assets/images/exclamation-icon.webp"
import circleExclamationImgFB from "../../assets/images/exclamation-icon.png"
import circleMinusImg from "../../assets/images/minus-icon.webp"
import circleMinusImgFB from "../../assets/images/minus-icon.png"
import circlePlusImg from "../../assets/images/plus-icon.webp"
import circlePlusImgFB from "../../assets/images/plus-icon.png"
import pricingPDF from "../../assets/images/myfembree-pricing.pdf"
import greenShield from "../../assets/images/GreenSheild.png"
import patternedImageMobile from "../../assets/images/patternedImageMobile.png"
import relievePain from "../../assets/images/relieve-pain.png"

const HomeContent = () => {
  const whatIsRef = useRef() //TODO: this name need to be reviewed
  const [shouldShow, setShouldShow] = useState(false)
  const linkGroups = [
    {
      type: "circles",
      links: [
        {
          color: "khaki",
          icon: "question",
          label: "WHAT IS ENDOMETRIOSIS?",
          link: "#what-is-endometriosis",
        },
        {
          color: "purple",
          icon: "handWave",
          label: "MEET MYFEMBREE",
          link: "#meet-myfembree",
        },
        {
          color: "fuchsia",
          icon: "stats",
          label: "FOR 3 TYPES<br/>OF ENDO PAIN",
          link: "#relieves-pain",
        },
        {
          color: "dark-green",
          icon: "sideEffects",
          label: "SIDE<br/>EFFECTS",
          link: "#side-effects",
        },
        {
          color: "blue",
          icon: "square",
          label: "COST &<br/> SUPPORT",
          link: "#cost-support",
        },
      ],
    },
  ]

  const handleScroll = () => {
    const currentY = document.documentElement.scrollTop
    const headerHeight = document.getElementsByClassName("main-header")[0]
      .clientHeight
    const whatIsTop = whatIsRef.current.offsetTop
    setShouldShow(currentY >= whatIsTop - headerHeight)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <Layout
      canonicalURL="https://www.myfembreeinfo.com/"
      title="Myfembree® (relugolix, estradiol, and norethindrone acetate) Tablets Patient Site"
      pageTitle="Myfembree® (relugolix, estradiol, and norethindrone acetate) Tablets Patient Site"
      description="See benefits, risks &amp; BOXED WARNING of Myfembree®. Learn about an Rx treatment for moderate to severe pain associated with endometriosis in premenopausal women."
    >
      <div className="section-wrapper">
        <section className="home-container">
          <img
            src={HeroHomeMobile}
            className="home-hero home-hero--mobile"
            alt=""
          />
          <img
            src={HeroHomeDesktop}
            className="home-hero home-hero--desktop"
            alt=""
          />
          <div className="home-content-container container">
            <h2 className="home-content-container__title">
              Find relief from the pain of endometriosis with Myfembree
            </h2>
            <p className="home-content-container__description">
              A once-daily pill to help premenopausal women experience less
              moderate to severe endometriosis (endo) pain.
            </p>
            <p className="home-content-container__description home-content-container__description--small">
              Myfembree should not be taken for more than 24 months.
            </p>
            <a href="/#meet-myfembree">
              <button className="cta-btn pink-btn home-content-container__cta">
                MEET MYFEMBREE
              </button>
            </a>
          </div>
          <div className="home-hero__gradiant"></div>
        </section>
      </div>
      <div className="section-wrapper">
        <section className="jump-links-section">
          <Container>
            <JumpLinks linkGroups={linkGroups} />
          </Container>
        </section>
      </div>
      <div className="section-wrapper" ref={whatIsRef}>
        <section className="what-is-endometriosis">
          <div className="home-page-link" id="what-is-endometriosis"></div>
          <Container>
            <h2 className="what-is-endometriosis__title">
              What is{" "}
              <span className="what-is-endometriosis__title what-is-endometriosis__title--larger">
                endometriosis
              </span>{" "}
              (“endo”)?
            </h2>
            <p className="what-is-endometriosis__description">
              The inner lining of your uterus, or womb, is called the{" "}
              <strong>endometrium.</strong> When you have endo, tissue similar
              to the endometrium is found outside of the uterus where it doesn’t
              belong.
            </p>
            <p className="what-is-endometriosis__description what-is-endometriosis__description--small-spacing">
              Although not every woman with endo may have the same experience,{" "}
              <strong>common signs and symptoms include:</strong>
            </p>
            <ul className="what-is-endometriosis__list">
              <li className="what-is-endometriosis__list-item">
                Pain during your period (menstrual pain)
              </li>
              <li className="what-is-endometriosis__list-item">
                Pain when you don't have your period (non-menstrual pelvic pain)
              </li>
              <li className="what-is-endometriosis__list-item">
                Pain during sex (dyspareunia)
              </li>
            </ul>
            <div className="what-is-endometriosis__patterned what-is-endometriosis__patterned--desktop">
              <BackgroundPattern className="what-is-endometriosis__background" />
              <div className="what-is-endometriosis__patterned-layout">
                <img
                  className="what-is-endometriosis__image"
                  src={patternedImageMobile}
                  alt=""
                />
                <div className="what-is-endometriosis__patterned-right">
                  <p className="what-is-endometriosis__quote">
                    Endo isn’t just a “bad period.”
                  </p>
                  <p className="what-is-endometriosis__introspection">
                    If you're one of the <span>1 in 10 women</span> suffering
                    from endo, you know that it's not just a “bad period.” The
                    pain could make you feel like there's no relief in sight.
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <div className="what-is-endometriosis__patterned what-is-endometriosis__patterned--mobile">
            <BackgroundPattern className="what-is-endometriosis__background" />
            <Container>
              <img
                className="what-is-endometriosis__image"
                src={patternedImageMobile}
                alt=""
              />
              <p className="what-is-endometriosis__quote">
                Endo isn’t just a “bad period.”
              </p>
              <p className="what-is-endometriosis__introspection">
                If you're one of the <span>1 in 10 women</span> suffering from
                endo, you know that it's not just a “bad period.” The pain could
                make you feel like there's no relief in sight.
              </p>
            </Container>
          </div>
        </section>
      </div>
      <div className="section-wrapper">
        <section className="meet-myfembree">
          <div className="home-page-link" id="meet-myfembree"></div>
          <Container>
            <div className="meet-myfembree__layout">
              <div className="meet-myfembree__left">
                <h2 className="meet-myfembree__title">
                  Meet{" "}
                  <span className="meet-myfembree__title meet-myfembree__title--larger">
                    Myfembree
                  </span>
                </h2>
                <p className="meet-myfembree__description">
                  Myfembree is a treatment that could fit your needs—and your
                  lifestyle. It’s{" "}
                  <strong>not a surgery, procedure, or injection.</strong>{" "}
                </p>
                <h3 className="meet-myfembree__subtitle">
                  3 ingredients in 1 small pill
                </h3>
                <p className="meet-myfembree__description">
                  Myfembree contains three key ingredients in one small pill (8
                  mm in diameter), designed to support an optimal hormone range
                  that may help reduce endo pain.{" "}
                </p>
                <ul className="meet-myfembree__list">
                  <li className="meet-myfembree__list-item">
                    <span className="meet-myfembree__list-item meet-myfembree__list-item--fuchsia">
                      Relugolix
                    </span>{" "}
                    may reduce the amount of estrogen (and other hormones)
                    produced by the body, helping to alleviate endo pain
                  </li>
                  <li className="meet-myfembree__list-item">
                    <span className="meet-myfembree__list-item meet-myfembree__list-item--fuchsia">
                      Estradiol
                    </span>{" "}
                    (an estrogen) may reduce the risk of bone loss that could
                    occur from taking relugolix alone
                  </li>
                  <li className="meet-myfembree__list-item">
                    <span className="meet-myfembree__list-item meet-myfembree__list-item--fuchsia">
                      Norethindrone acetate
                    </span>{" "}
                    (a progestin) may protect the uterus from the effect of
                    taking estrogen alone
                  </li>
                </ul>
                <h4 className="meet-myfembree__disclosure">
                  Myfembree should not be taken for more than 24 months.
                </h4>
              </div>
              <div className="meet-myfembree__right">
                <div className="meet-myfembree__image">
                  <img src={brochureImg} alt="" />
                </div>
                <div className="meet-myfembree__image-description">
                  Find out more about taking Myfembree
                </div>
                <a href={brochurePDF} target="_blank">
                  <button className="cta-btn pink-btn meet-myfembree__cta">
                    View the Brochure
                  </button>
                </a>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <div className="section-wrapper">
        <section className="relieves-pain">
          <div className="home-page-link" id="relieves-pain"></div>
          <Container>
            <h2 className="relieves-pain__title">
              Proven to relieve{" "}
              <span className="relieves-pain__title relieves-pain__title--larger">
                3 types of endo pain
              </span>
            </h2>
            <div className="relieves-pain__layout">
              <div className="relieves-pain__left">
                <h3 className="relieves-pain__subtitle">
                  Myfembree is clinically proven to relieve moderate to severe
                  endo pain:
                </h3>
                <div className="relieves-pain__sub-section">
                  <div className="relieves-pain__sub-left">
                    <RelievePain className="relieves-pain__sub-left-icon" />
                  </div>
                  <div className="relieves-pain__sub-right">
                    <p className="relieves-pain__sub-right-item">
                      Pain during your period (menstrual pain)
                    </p>
                    <p className="relieves-pain__sub-right-item">
                      Pain when you don't have your period
                      <br className="relieves-pain__line-break" />{" "}
                      (non-menstrual pelvic pain)
                    </p>
                    <p className="relieves-pain__sub-right-item">
                      Pain during sex (dyspareunia)
                      <sup className="sup sup--astrix">*</sup>
                    </p>
                    <ul className="relieves-pain__disclosure relieves-pain__disclosure--more-top">
                      <li className="relieves-pain__disclosure-item relieves-pain__disclosure-item--astrix">
                        In women who reported painful sex at the start of and
                        during the studies.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="relieves-pain__sub-section-image">
                  <img
                    className="relieves-pain__image"
                    src={relievePain}
                    alt=""
                  />
                </div>
                <p className="relieves-pain__text">
                  <span className="relieves-pain__text relieves-pain__text--purple">
                    7 out of 10 women<sup>†</sup>
                  </span>{" "}
                  with moderate to severe menstrual pain and{" "}
                  <span className="relieves-pain__text relieves-pain__text--purple">
                    half of women
                    <sup>‡</sup>
                  </span>{" "}
                  with non-menstrual pelvic pain experienced relief with
                  Myfembree.
                </p>
                <p className="relieves-pain__text">
                  Additionally in studies, women were asked how often their endo
                  pain made them unable to engage in activities or do basic
                  things in the last 4 weeks (eg, attend a social event, do jobs
                  around the house, sitting, exercising, sleeping). Women taking
                  Myfembree{" "}
                  <span className="relieves-pain__text relieves-pain__text--purple">
                    reported an improvement in the overall impact
                  </span>{" "}
                  that endo pain had on their daily activities.
                </p>
                <ul className="relieves-pain__disclosure">
                  <li className="relieves-pain__disclosure-item relieves-pain__disclosure-item--dagger">
                    At week 24, compared with 3 out of 10 women on placebo.
                  </li>
                  <li className="relieves-pain__disclosure-item relieves-pain__disclosure-item--double-dagger">
                    At week 24, compared with 4 out of 10 women on placebo.
                  </li>
                </ul>
              </div>
              <div className="relieves-pain__right">
                <img
                  className="relieves-pain__image"
                  src={relievePain}
                  alt=""
                />
              </div>
            </div>
            <div className="relieves-pain__important important">
              <Calendar className="important__icon" />
              <div className="important__text">
                It's very important to start Myfembree as soon as possible after
                your period begins, and no later than 7 days after it has
                started. If you start Myfembree more than 7 days after the start
                of your period, irregular and heavy bleeding may occur at first.
              </div>
            </div>
          </Container>
        </section>
      </div>
      <div className="section-wrapper">
        <section className="side-effects">
          <div className="home-page-link" id="side-effects"></div>
          <Container>
            <h2 className="side-effects__title">
              Understanding <span>Side Effects</span>
            </h2>
            <p className="side-effects__description">
              The safety of Myfembree was tested in 2 clinical studies.
            </p>
            <div className="image-and-text">
              <h3 className="image-and-text__label">
                Most common side effects
              </h3>
              <picture>
                <source
                  srcSet={circlePlusImg}
                  className="circle-img image-and-text__image"
                  alt="Plus Sign"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  src={circlePlusImgFB}
                  className="circle-img image-and-text__image"
                  alt="Plus Sign"
                  width="100"
                  height="100"
                />
              </picture>
              <p className="image-and-text__text">
                The most common side effects of taking Myfembree for moderate to
                severe endometriosis pain were headache; hot flushes, sweating,
                or night sweats; mood changes, including worsening depression;
                abnormal vaginal bleeding (bleeding that lasts too long, that is
                too much, or is unexpected); nausea; toothache; back pain;
                decreased interest in sex; joint pain; tiredness; and dizziness.
              </p>
            </div>
            <div className="image-and-text">
              <h3 className="image-and-text__label">Serious side effects</h3>
              <picture>
                <source
                  srcSet={circleExclamationImg}
                  className="circle-img image-and-text__image"
                  alt="Exclamation Point"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  src={circleExclamationImgFB}
                  className="circle-img image-and-text__image"
                  alt="Exclamation Point"
                  width="100"
                  height="100"
                />
              </picture>
              <p className="image-and-text__text">
                Serious side effects were reported in 2.9% of women on Myfembree
                and 2.2% of women on placebo. Serious side effects were uterine
                hemorrhage, suicidal ideation, gallstones, and gallbladder
                inflammation.
              </p>
            </div>
            <div className="image-and-text">
              <h3 className="image-and-text__label">Discontinuations</h3>
              <picture>
                <source
                  srcSet={circleMinusImg}
                  className="circle-img image-and-text__image"
                  alt="Minus Sign"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  src={circleMinusImgFB}
                  className="circle-img image-and-text__image"
                  alt="Minus Sign"
                  width="100"
                  height="100"
                />
              </picture>
              <p className="image-and-text__text">
                In clinical trials, 4.5% of women treated with Myfembree stopped
                taking it because of side effects, compared with 2.9% of women
                in the placebo group. The most common side effect that led to
                discontinuation for women taking Myfembree was mood-related
                disorders (1.7%).
              </p>
            </div>
            <p className="side-effects__disclosure">
              These are not all the possible side effects of Myfembree.
            </p>
          </Container>
        </section>
      </div>
      <section className="cost-support">
        <div className="home-page-link" id="cost-support"></div>
        <Container>
          <div className="cost-support__container">
            <h2 className="cost-support__title">Cost & Support</h2>
            <p className="cost-support__description">
              The Myfembree<sup>®</sup> Support Program may help people
              prescribed Myfembree with coverage needs.
            </p>
            <div className="cost-support__insurance">
              <img
                className="cost-support__insurance-icon"
                src={blueShield}
                alt=""
              />
              <div>
                <h3 className="cost-support__insurance-title">
                  Insurance from your job or purchased on your own
                </h3>
                <p className="cost-support__insurance-description">
                  The Myfembree Copay Assistance Program can provide copay
                  support to people with commercial insurance who are prescribed
                  Myfembree.
                </p>
                <a
                  className="cost-support__insurance-button"
                  href="/commercial-insurance"
                >
                  <button className="cta-btn pink-btn">
                    <span className="text">
                      Learn about the Copay Assistance Program
                    </span>
                    <InternalArrow className="icon" />
                  </button>
                </a>
              </div>
            </div>
            <div className="cost-support__insurance">
              <img
                className="cost-support__insurance-icon"
                src={greenShield}
                alt=""
              />
              <div>
                <h3 className="cost-support__insurance-title">
                  No insurance, or if you have received a denial
                </h3>
                <p className="cost-support__insurance-description">
                  The Myovant Patient Assistance Program can provide Myfembree
                  at no cost to eligible people with a coverage denial or no
                  insurance coverage.
                </p>
                <p className="cost-support__insurance-description">
                  The Myovant Patient Assistance Program can provide a supply of
                  Myfembree as long as a person can meet and maintain
                  eligibility requirements. This free medication supply is
                  fulfilled by TC&nbsp;Script, a noncommercial dispensing
                  pharmacy.
                </p>
              </div>
            </div>
          </div>
          <div className="cost-support__container cost-support__container--alt">
            <div className="bubble bubble--full">
              <div className="left-column">
                <h3 className="bubble__title">
                  Have questions about enrollment?
                </h3>
                <p className="bubble__description">
                  Call to speak with a dedicated support staff member who will
                  guide you through the enrollment process
                </p>
                <p className="bubble__extra">
                  <span className="number">
                    1-833-MYFEMBREE (1-833-693-3627)
                  </span>
                  <span className="hours">
                    Monday–Friday, 8 <sub>AM</sub>-8 <sub>PM</sub> ET
                  </span>
                </p>
              </div>
              <div className="right-column">
                <a
                  className="bubble__button bubble__button--call"
                  href="tel:1-833-693-3627"
                >
                  <button className="cta-btn pink-btn">
                    <Phone className="icon" />
                    <span className="text">Call Now</span>
                  </button>
                </a>
              </div>
            </div>
            <div className="bubble">
              <h3 className="bubble__title">Have a question about cost?</h3>
              <p className="bubble__description">
                Understand what you may potentially pay for your medicine
              </p>
              <a
                className="bubble__button bubble__button--download"
                href={pricingPDF}
                target="_blank"
              >
                <button className="cta-btn pink-btn">
                  <DownloadCircle className="icon" />
                  <span className="text">Out-of-Pocket Costs Guide</span>
                </button>
              </a>
            </div>
            <div className="bubble">
              <h3 className="bubble__title">Ready to enroll?</h3>
              <p className="bubble__description">
                You can begin enrollment by providing your e-Consent here*
              </p>
              <ExternalLink
                className="bubble__button bubble__button--e-consent"
                externalUrl={
                  "https://portal.trialcard.com/myovant/myfembree/consent/"
                }
              >
                <button className="cta-btn pink-btn">
                  <span className="text">Provide e-Consent</span>
                  <ExternalIcon className="icon" />
                </button>
              </ExternalLink>
              <p className="bubble__disclosure">
                <sup>*</sup>Enrollment in the Myfembree Support Program will
                occur only after your healthcare provider has prescribed
                Myfembree and completed the Myfembree Support Program Enrollment
                Form.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <FloatingLinks shouldShow={shouldShow} />
    </Layout>
  )
}

export default HomeContent
